<script>
//import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Create Handover to Customer",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    //DatePicker,
    Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Create Handover to Customer",
      items: [],
      value: null,
      options_customer: [
        "PT A Express",
        "PT B Logistics",
        "CV Cepat Jaya"
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

  <form class="form-horizontal" role="form">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Customer"
                    label-for="vendflight_vendorid"
                  >
                    <multiselect v-model="value" :options="options_customer"></multiselect>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Unpaid"
                    label-for="vendflight_vendorid"
                  >
                    <div class="pt-2">: &nbsp; 80.000</div>
                  </b-form-group>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->


    <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="SMU No."
                    label-for="customer_contactphone"
                  >
                    <b-form-input id="mvs_processadd"  value=""></b-form-input>
                    <b-button variant="primary">Add</b-button>
                  </b-form-group>
            </div>
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="SMU No."
                    label-for="customer_contactphone"
                  >
                    <b-form-input id="mvs_processadd"  value=""></b-form-input>
                    <b-button variant="danger">Remove</b-button>
                  </b-form-group>
            </div>
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Process</h4>
              <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                      <table class="table mb-0 font-size-13">
                        <thead>
                        <tr>
                          <th>No.</th>
                          <th>SMU No.</th>
                          <th>Departure</th>
                          <th>Airline</th>
                          <th>Flight</th>
                          <th>Route</th>
                          <th>Total Weight</th>
                          <th>Unpaid</th>
                          <th>Collies Ordered</th>
                          <th>Collies Handover</th>
                          <th>#</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td>000-123123001</td>
                            <td>09-FEB-2021 01:45</td>
                            <td>TRI-MG</td>
                            <td>GM705</td>
                            <td>KNO-HLP</td>
                            <td class="text-right">112</td>
                            <td class="text-right">
                              <span class="badge badge-soft-success font-size-13">0</span>
                            </td>
                            <td class="text-right">6</td>
                            <td width="15"><b-form-input value="" class="font-size-13"></b-form-input></td>
                            <td>
                              <b-button size="sm" variant="danger">
                                <i class="ri-delete-bin-line"></i>
                              </b-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- end table -->
                  </div>
                </div>
                <!-- end row -->
              </div>
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->

        
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Outstanding</h4>
              <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                      <table class="table mb-0 font-size-13">
                        <thead>
                        <tr>
                          <th>No.</th>
                          <th>SMU No.</th>
                          <th>Departure</th>
                          <th>Airline</th>
                          <th>Flight</th>
                          <th>Route</th>
                          <th>Total Weight</th>
                          <th>Unpaid</th>
                          <th>Collies Ordered</th>
                          <th>#</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td>000-123123002</td>
                            <td>09-FEB-2021 16:25</td>
                            <td>TRI-MG</td>
                            <td>GM727</td>
                            <td>PNK-HLP</td>
                            <td class="text-right">105</td>
                            <td class="text-right">
                              <span class="badge badge-soft-danger font-size-13">80.000</span>
                            </td>
                            <td class="text-right">5</td>
                            <td>
                              <b-button size="sm" variant="primary">
                                <i class="ri-add-line"></i>
                              </b-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- end table -->
                  </div>
                </div>
                <!-- end row -->
              </div>
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
  </form>
  </Layout>
</template>